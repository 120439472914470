var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", scrollable: "", "max-width": "600" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "secondary--text justify-start",
                    attrs: { small: "", text: "", block: "" },
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { small: "", color: "primary" } }, [
                    _vm._v("add"),
                  ]),
                  _vm._v(" Assign role "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "text-uppercase secondary--text" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-1", attrs: { color: "secondary" } },
                  [_vm._v("group_add")]
                ),
                _vm._v(" assign roles "),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c(
                "v-alert",
                { attrs: { text: "", prominent: "", color: "info" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v("Assignee: " + _vm._s(_vm.assignee)),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "shrink" },
                        [
                          _vm.assignmentTab ===
                          _vm.assignmentTabOptions.SELECT_ROLES_TAB
                            ? _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.expand = !_vm.expand
                                    },
                                  },
                                },
                                [_vm._v("search")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.assignmentTab,
                    callback: function ($$v) {
                      _vm.assignmentTab = $$v
                    },
                    expression: "assignmentTab",
                  },
                },
                [
                  _c("v-tab", { attrs: { disabled: _vm.assigningRole } }, [
                    _vm._v("Select roles"),
                  ]),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-container",
                        [
                          _vm.expand
                            ? _c("v-text-field", {
                                attrs: {
                                  label: "Search..",
                                  outlined: "",
                                  dense: "",
                                },
                                model: {
                                  value: _vm.targetSearch,
                                  callback: function ($$v) {
                                    _vm.targetSearch = $$v
                                  },
                                  expression: "targetSearch",
                                },
                              })
                            : _vm._e(),
                          _vm.spaceName &&
                          _vm.spaceRole !== _vm.roleTypes.SPACE_ADMIN &&
                          _vm.spaceName
                            .toLowerCase()
                            .includes(_vm.targetSearch.toLowerCase())
                            ? _c(
                                "v-row",
                                {
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          disabled: _vm.assigningRole,
                                          color: "primary",
                                        },
                                        model: {
                                          value: _vm.spaceRoleBox,
                                          callback: function ($$v) {
                                            _vm.spaceRoleBox = $$v
                                          },
                                          expression: "spaceRoleBox",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "11" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-flex align-center ma-0 pl-2 mt-3 font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.spaceName) + " "
                                          ),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                "x-small": "",
                                                color: "primary",
                                              },
                                            },
                                            [_vm._v("space")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-checkbox", {
                                        staticClass: "ma-0 pl-2 pb-0",
                                        attrs: {
                                          disabled:
                                            !_vm.spaceRoleBox ||
                                            _vm.assigningRole,
                                          row: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    { staticClass: "caption" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.spaceRoleLabels
                                                            .SPACE_ADMIN
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1351068858
                                        ),
                                        model: {
                                          value: _vm.spaceAdminRole,
                                          callback: function ($$v) {
                                            _vm.spaceAdminRole = $$v
                                          },
                                          expression: "spaceAdminRole",
                                        },
                                      }),
                                      _vm.filteredInstanceList.length > 0
                                        ? _c("v-divider")
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(
                            _vm.filteredInstanceList,
                            function (instance, i) {
                              return _c(
                                "v-row",
                                {
                                  key: instance.iid,
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          disabled: _vm.assigningRole,
                                          color: "primary",
                                        },
                                        model: {
                                          value: instance.selectedInstance,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              instance,
                                              "selectedInstance",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "instance.selectedInstance",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "11" } },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-flex align-center ma-0 pl-2 mt-3 font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    instance.instance_name
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "ml-1 caption",
                                                  attrs: {
                                                    "x-small": "",
                                                    color: "secondary",
                                                    outlined: "",
                                                  },
                                                },
                                                [_vm._v("Instance")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "ma-0 pl-2 pb-0",
                                              attrs: {
                                                disabled:
                                                  !instance.selectedInstance ||
                                                  _vm.assigningRole,
                                                row: "",
                                              },
                                              model: {
                                                value: instance.selectedRole,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    instance,
                                                    "selectedRole",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "instance.selectedRole",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  value:
                                                    _vm.roleTypes
                                                      .INSTANCE_EDITOR,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "caption",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.roleTypes
                                                                    .INSTANCE_EDITOR
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  value:
                                                    _vm.roleTypes
                                                      .INSTANCE_VIEWER,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "caption",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.roleTypes
                                                                    .INSTANCE_VIEWER
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      i < _vm.filteredInstanceList.length - 1
                                        ? _c("v-divider")
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("v-tab", { attrs: { disabled: _vm.disableReviewTab } }, [
                    _vm._v("review outcome"),
                  ]),
                  _c(
                    "v-tab-item",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "v-container",
                        _vm._l(
                          _vm.roleAssignmentOutcome,
                          function (outcome, index) {
                            return _c(
                              "v-row",
                              { key: index, attrs: { align: "center" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    outcome.outcome === "failure"
                                      ? _c(
                                          "v-chip",
                                          {
                                            staticClass:
                                              "font-weight-bold caption text-uppercase",
                                            attrs: {
                                              small: "",
                                              color: "error",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-avatar",
                                              { attrs: { left: "" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("cancel")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" failed "),
                                          ],
                                          1
                                        )
                                      : outcome.outcome === "success"
                                      ? _c(
                                          "v-chip",
                                          {
                                            staticClass:
                                              "font-weight-bold caption text-uppercase",
                                            attrs: {
                                              small: "",
                                              color: "success",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-avatar",
                                              { attrs: { left: "" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("check_circle")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" assigned "),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("v-col", { attrs: { cols: "9" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column subtitle-2 text-uppercase",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("role: " + _vm._s(outcome.role)),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          "target: " + _vm._s(outcome.target)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _vm.assignmentTab === _vm.assignmentTabOptions.SELECT_ROLES_TAB
                ? _c(
                    "span",
                    { staticClass: "caption text-uppercase" },
                    [
                      _vm.selectedRolesLength === 1
                        ? [
                            _vm._v(
                              _vm._s(_vm.selectedRolesLength) + " role Selected"
                            ),
                          ]
                        : [
                            _vm._v(
                              _vm._s(_vm.selectedRolesLength) +
                                " roles Selected"
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.assigningRole,
                    color: "secondary",
                    text: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.clearAndCloseDialog()
                    },
                  },
                },
                [_vm._v("close")]
              ),
              !_vm.finishedAssignments
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.assigningRole,
                        disabled: !_vm.activateAssignButton,
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.assignRoles()
                        },
                      },
                    },
                    [_vm._v(" assign ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }